import React from 'react'

import { SchoolHeader, Header} from './containers';
import { CTA, Brand, Navbar } from './components';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <div>
      {/* <Navbar /> */}
      <Header />
    </div>
    <SchoolHeader />
    {/*<Brand />
    <WhatGPT3 />
    <Features />
    <CTA />
    <Blog />
    <Footer />*/}
    </div>
  )
}

export default App
